@import "@alltrails/shared/styles/landsEnd.scss";
.container {
  @include flex-row;
  align-items: center;
  gap: var(--space-150);
  width: max-content;
  max-width: 100%;
  @include userSelectNone;
  cursor: pointer;
  // to keep hidden input contained here
  position: relative;

  &.disabled {
    cursor: default;

    .label {
      color: var(--color-text-disabled);
    }
  }

  &.full {
    width: 100%;
    justify-content: space-between;
  }

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked ~ .switch {
    padding-left: calc(
      48px - 2 * var(--borders-weight-sm) - 20px - $borders-weight-sm
    ); // switch width - border left/right - icon width - right padding
  }

  input:focus-visible ~ .switch {
    box-shadow: 0 0 0 var(--borders-weight-md) var(--color-border-focus-default);
  }

  input:checked:focus-visible ~ .switch {
    box-shadow: 0 0 0 var(--borders-weight-md) var(--color-border-focus-primary);
  }

  input:checked:not(:disabled) ~ .switch {
    border-color: transparent;
    background-color: var(--color-interactive-primary);
    color: var(--color-neutral-white);
  }

  input:disabled ~ .switch {
    border-color: var(--color-border-disabled);
    background-color: var(--color-background-disabled);
    color: var(--color-text-disabled);
  }

  @include hover {
    input:not(:checked):not(:disabled) ~ .switch {
      border-color: var(--color-border-interactive-hover);
    }

    input:checked:not(:disabled) ~ .switch {
      background-color: var(--color-interactive-primary-hover);
    }
  }
}

.label {
  @include text-200;
}

.switch {
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 48px;
  height: 24px;
  padding-left: var(--borders-weight-sm);
  border: var(--borders-weight-sm) solid var(--color-border-interactive);
  border-radius: var(--radius-round);
  background-color: var(--color-background-primary);
  color: var(--color-neutral-700);
  transition: all 0.2s ease-out;

  svg {
    height: 20px !important;
    width: 20px !important;
  }
}
