@import "@alltrails/shared/styles/landsEnd.scss";
.row {
  @include removeDefaultButtonStyles;
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--space-16);
  align-self: stretch;
  justify-content: space-between;
  cursor: pointer;
  padding: var(--space-16);

  &:hover {
    border-radius: var(--radius-lg);
    background-color: var(--color-background-secondary);
  }
}
