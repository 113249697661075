@import "@alltrails/shared/styles/landsEnd.scss";
.mapContainer {
  height: 100%;
  border-radius: var(--radius-xl);
  overflow: hidden;

  @include page-width-md-down {
    width: 100%;
    aspect-ratio: 1.333;
  }
}

.mobileMapContainer {
  height: 100dvh;
  width: 100vw;
  overflow: hidden;

  :global(.mapboxgl-popup-content) {
    padding: 0 0 var(--space-200) 0 !important;
  }
}

.map {
  display: none;
}

.showMap {
  display: contents;
}

.shimmerContainer {
  background-color: rgba(76, 64, 26, 0.08);
  height: 100%;
}

.mapModal {
  width: 100vw;
  height: 100vh;
  max-width: none;
  border-radius: 0;
  max-height: none;
  padding: 0;
  margin: 0;
}

// temporary workaround for styling import order issue
// https://github.com/vercel/next.js/issues/65480
.expand.expand {
  position: absolute;
  right: var(--space-200);
  top: var(--space-200);
  z-index: var(--z-index-default);
}

// temporary workaround for styling import order issue
// https://github.com/vercel/next.js/issues/65480
.exploreMore.exploreMore {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  z-index: var(--z-index-default);

  &:not(.loading):not(.disabled):active {
    transform: scale(0.96) translateX(-50%); // maintain existing scale behavior on click
  }
}

.mapRendererContainer {
  height: 100%;
  position: relative;
}

.mobileClickableMap {
  position: absolute;
  width: 100%;
  cursor: pointer;
  z-index: var(--z-index-default);
  aspect-ratio: 1.333;
}
