@import "@alltrails/shared/styles/landsEnd.scss";
.container {
  // Prevent any SVG icons from having additional spacing, which has been an issue for us.
  // https://stackoverflow.com/questions/24626908/how-to-get-rid-of-extra-space-below-svg-in-div-element
  // https://github.com/alltrails/monorepo/pull/435#discussion_r854479822
  vertical-align: top;
}

.xs {
  width: var(--size-icon-xs);
  height: var(--size-icon-xs);
}
.sm {
  width: var(--size-icon-sm);
  height: var(--size-icon-sm);
}
.md {
  width: var(--size-icon-md);
  height: var(--size-icon-md);
}
.lg {
  width: var(--size-icon-lg);
  height: var(--size-icon-lg);
}
