@import "@alltrails/shared/styles/landsEnd.scss";
.toast {
  --width: min(420px, calc(100dvw - 2 * var(--space-200)));

  z-index: 1003; // match modal z-index
  position: fixed;
  width: var(--width);
  left: calc(50% - var(--width) / 2);
  @include text-200;
  @include flex-row;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-200);
  gap: var(--space-8);
  border-radius: var(--radius-lg);
  color: var(--color-text-primary-inverse);
}

.primary {
  top: var(--space-16);

  &.isVisible {
    opacity: 1;
    transform: translateY(100%);
  }

  &:not(.isVisible) {
    opacity: 0;
    transform: translateY(0);
  }

  &.isAnimating {
    animation: slide-in-top 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
    &.isVisible {
      animation-direction: reverse;
    }
  }
}

.alternative {
  bottom: var(--space-48);

  &.isVisible {
    opacity: 1;
    transform: translateY(0);
  }

  &:not(.isVisible) {
    opacity: 0;
    transform: translateY(100%);
  }

  &.isAnimating {
    animation: slide-in-bottom 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
    &.isVisible {
      animation-direction: reverse;
    }
  }
}

.textOnly {
  justify-content: center;
}

.success {
  background-color: var(--color-background-inverse);
}

.error {
  background-color: var(--color-background-error-base);
}

.iconContainer {
  @include flex-row;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
}

.message {
  margin-left: var(--space-12);
}

.truncating {
  --max-lines: 4;
  overflow: hidden;
  max-height: calc(var(--lh) * var(--max-lines));
}

.actionLink {
  cursor: pointer;
  @include text-200-bold;
  background-color: transparent;
  border: none;
  color: var(--color-text-primary-inverse);
  text-decoration: underline;
}

@keyframes slide-in-top {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(100%);
  }
}

@keyframes slide-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@include page-width-xs {
  .toast {
    --width: calc(100dvw - 2 * var(--breakpoint-xs-margin));

    width: var(--width);
    left: calc(50% - var(--width) / 2);
  }
}
