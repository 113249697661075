@import "@alltrails/shared/styles/landsEnd.scss";
.flexContainer {
  @include flex-column;
  align-items: center;
  background-color: var(--color-background-accent-strong);
}

.headerImageWrapper {
  width: 100%;
  height: 60vh;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.header {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.margins {
  margin: 0 var(--space-300);
  max-width: 1240px;

  @include page-width-md-up {
    margin: 0 var(--space-600);
  }

  @include page-width-lg {
    margin: 0 100px;
  }

  @include minWidth(1440px) {
    margin: 0 auto;
  }
}

.heading {
  margin: 0 0 var(--space-600) 0;
  @include heading-700;
  color: var(--color-neutral-white);
}

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  color: var(--color-text-light);

  padding: 5rem var(--space-200);

  @include page-width-sm-up {
    padding: 5rem var(--space-300);
  }
  @include page-width-md-up {
    padding-left: var(--space-600);
    padding-right: var(--space-600);
  }

  @include page-width-lg {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
    max-width: 1440px;
  }

  p {
    @include text-300;
    color: var(--color-text-light);
  }
}

.trailListHeader {
  @include heading-600;
  color: var(--color-text-light);
}
