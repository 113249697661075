@import "@alltrails/shared/styles/landsEnd.scss";
.list {
  padding: 0;
  max-width: 800px;
}

.item {
  display: flex;
  gap: 12px;
  // negative margin so that hover state goes outside of container
  margin: -12px -12px var(--space-200) -12px;
  border-radius: var(--radius-lg);
  align-items: center;
  padding: 12px;

  @include page-width-md-up {
    gap: var(--space-300);
    margin: -12px -12px var(--space-300) -12px;
  }
}

.mobileNumberColumn {
  @include text-300;

  @include page-width-md-up {
    display: none;
  }
}

.desktopNumberPrefix {
  display: none;
  margin-right: var(--space-50);

  @include page-width-md-up {
    display: inline-block;
  }
}

.image {
  object-fit: cover;
  width: 64px;
  height: 64px;
  border-radius: var(--radius-md);
  flex-shrink: 0;

  @include page-width-md-up {
    width: 248px;
    height: 140px;
    border-radius: var(--radius-lg);
  }
}

.arrowButton {
  display: none;
  flex-shrink: 0;

  @include page-width-md-up {
    display: block;
  }
}

.heading {
  @include heading-200;
  margin: 0;

  @include page-width-md-up {
    @include heading-300;
    margin: 0 0 var(--space-100) 0;
  }
}

.stats {
  display: flex;
  align-items: center;
  @include text-100;

  @include page-width-md-up {
    @include text-200;
  }
}

.star {
  margin-right: 4px;
  width: 12px;
  min-width: 12px;

  @include page-width-md-up {
    width: unset;
  }
}

.ratingSpacing {
  margin-right: 4px;
}

.distance {
  white-space: nowrap;
}

.duration {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textContent {
  overflow: hidden;
  flex-grow: 1;
}

// variants
.primary {
  .item {
    @include page-width-md-up {
      &:hover {
        background-color: #0f1e00;
      }
    }
  }

  .mobileNumberColumn {
    color: var(--color-neutral-white);
  }

  .heading {
    color: var(--color-neutral-white);

    @include page-width-md-up {
      color: var(--color-neutral-white);
    }
  }

  .stats {
    color: var(--color-neutral-white);

    @include page-width-md-up {
      color: var(--color-neutral-white);
    }
  }
}

.inverse {
  .item {
    @include page-width-md-up {
      &:hover {
        background-color: var(--color-neutral-50);
      }
    }
  }

  .mobileNumberColumn {
    color: var(--color-text-primary);
  }

  .heading {
    color: var(--color-text-primary);

    @include page-width-md-up {
      color: var(--color-text-primary);
    }
  }

  .stats {
    color: var(--color-text-primary);

    @include page-width-md-up {
      color: var(--color-text-primary);
    }
  }
}
