@import "@alltrails/shared/styles/landsEnd.scss";
$breakpoint-760: 760px;
$bottom-border-radius-height: var(--radius-lg);
$actual-top-content-height: 50vh;
$top-div-visible-height: calc($actual-top-content-height + $bottom-border-radius-height);
$min-height: 260px;

.emptyPhoto {
  height: 54px; // designs call for 64px, but ResponsivePageLayout has a 10px div that is used for scroll listening
}

.photoHeaderContainer {
  width: 100%;

  @include page-width-lg {
    height: 100%;
  }

  @include page-width-md-up {
    border-radius: var(--radius-card);
  }

  @include page-width-md-down {
    width: 100%;
    height: 100%;
    min-height: 260px;

    & > div {
      height: 100%;
    }
  }
}

.carouselContainer {
  background-color: var(--color-neutral-200);
  overflow: hidden;
  height: 100%;
  aspect-ratio: 1.5;

  :global(.swiper-pagination) {
    bottom: 56px !important;
    height: 10px;
    z-index: var(--z-index-default);
  }

  @include page-width-md-up {
    border-radius: var(--radius-card);

    :global(.swiper-pagination) {
      bottom: 24px !important;
    }
  }

  @include page-width-md-down {
    aspect-ratio: 1.5;
    max-height: 480px;
  }
}

.carouselImageOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  z-index: var(--z-index-default);
}

.carouselImage {
  @include removeDefaultButtonStyles;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.locationPhoto {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all var(--duration-short) ease-in-out;
}

.container {
  isolation: isolate; // Prevents photo overflow on safari https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
  background-color: var(--color-neutral-200);
  overflow: hidden;
  width: 100%;

  @media (max-width: $breakpoint-760) {
    height: $top-div-visible-height;
    min-height: $min-height;
  }

  @media (min-width: $breakpoint-760) {
    border-radius: var(--radius-card);
    aspect-ratio: 1.5;
  }
}

.showMore {
  @include flex-row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.noImage {
  aspect-ratio: 1.5;
  @include flex-row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(76, 64, 26, 0.08);
}
